.fancybox {
  padding: 31px 31px 37px;
  max-width: 550px;

  &.medium {
    min-width: 430px;
  }

  .c-img {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 25px;
  }

  .c-header {
    font-size: 24px;
    text-align: center;
    line-height: 36px;
    color: #5b5b5bff;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .btn-row {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 90%;
    margin: auto;
    justify-content: space-between;

    &.separate {
      max-width: 100%;
      & > * {
        margin: 0 5px;
      }
    }

    & > * {
      //flex-grow: 1;

      img {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }

  .title {
    display: block;
    color: #5b5b5bff;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1;
  }
  .removed {
    margin-top: 35px;
    display: block;
    font-size: 0;
    h3 {
      display: inline-block;
      vertical-align: middle;
      color: #e13339;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
    }
    .restore {
      display: inline-block;
      vertical-align: middle;
      margin-left: 45px;
      color: var(--primary-color);
      font-size: 15px;
      text-transform: uppercase;
      line-height: 1.2;
      border-bottom: 1px solid var(--primary-color);
      text-decoration: none;
    }
  }
  .remove {
    display: inline-block;
    margin-top: 35px;
    color: #e13339;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid #e13339;
    line-height: 1.2;
  }
  .blockHeading {
    display: block;
    margin-top: 35px;
    color: #424242;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1;
  }
  .line {
    margin-top: 30px;
    font-size: 0;
    label {
      display: inline-block;
      vertical-align: middle;
      color: #424242;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 1.3;
      width: 130px;
      text-align: right;
      margin: 0;
    }
    input {
      display: inline-block;
      vertical-align: middle;
      width: 330px;
      border: none;
      border-bottom: 1px solid $color-lightBlack-active;
      color: #424242;
      font-size: 18px;
      line-height: 1;
      padding: 3px 15px 9px;
      &[name='macAddress'] {
        font-size: 14px;
        &:disabled {
          font-size: 14px;
          padding-top: 8px;
        }
      }
      &:disabled {
        font-size: 18px;
        border-bottom: none;
        background: transparent;
      }
    }
    .input__error {
      border-bottom: 1px solid red;
    }
    .error {
      display: none;
      position: absolute;
      font-size: 13px;
      color: #e13339;
    }
    &.no-valid {
      input {
        border-color: #e13339 !important;
        color: #e13339 !important;
      }
      .error {
        display: block;
      }
    }
  }
  .users {
    font-size: 0;
    p {
      margin-top: 20px;
      color: #424242;
      line-height: 1;
      font-size: 14px;
      font-weight: 300;
    }
    .select2-container {
      width: auto !important;
    }
    .select2-selection {
      position: relative;
      display: inline-block;
      margin-top: 30px;
      vertical-align: middle;
      width: 290px;
      min-height: 30px;
      padding-right: 20px;
      line-height: 1.2;
      font-size: 18px;
      color: #424242;
      border: none;
      border-bottom: 1px solid $color-lightBlack-active;
      outline: none;
      border-radius: 0;
      &[aria-expanded='true'] {
        &:before {
          transform: rotate(-180deg);
        }
      }
      &:before {
        position: absolute;
        content: '';
        width: 11px;
        height: 6px;
        background: url('^assets/img/arrow_down.png') center center no-repeat;
        right: 5px;
        top: 50%;
        margin-top: -3px;
        transition: all 0.3s ease;
      }
      .select2-selection__choice {
        color: #424242;
        font-size: 18px;
        background: transparent;
        border: none;
        line-height: 1.2;
        margin-bottom: 5px;
      }
    }
    button {
      display: inline-block;
      vertical-align: middle;
      margin: 30px 0 0 20px;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
      padding: 10px 35px;
      color: var(--primary-color);
      line-height: 1;
      font-size: 15px;
      text-transform: uppercase;
      outline: none;
      cursor: pointer;
      background: transparent;
      &.active {
        background: var(--primary-color);
        color: #fff;
      }
    }
    .user {
      display: inline-block;
      vertical-align: middle;
      color: #424242;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      margin: 20px 30px 0 0;
      .removeUser {
        display: inline-block;
        vertical-align: middle;
        margin: 2px 0 0 10px;
      }
    }
  }
  .buttons {
    margin-top: 30px;
    font-size: 0;
    text-align: right;
    .cancel {
      display: inline-block;
      vertical-align: middle;
      color: var(--light-color);
      font-size: 15px;
      text-transform: uppercase;
      line-height: 1.2;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 2px 5px;
    }
    .save {
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      border-radius: 5px;
      background-color: var(--primary-color);
      padding: 10px 37px;
      color: #ffffff;
      font-size: 15px;
      text-transform: uppercase;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  &.warning {
    padding: 64px 58px 40px;
    text-align: center;
    .title {
      display: block;
      margin-top: 14px;
      color: #df242b;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
    }
    p {
      margin-top: 35px;
      color: #5b5b5bff;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;
    }
    .buttons {
      text-align: center;
      .cancel {
        margin-left: 25px;
      }
      .save {
        //padding: 7px 24px;
        margin-left: 48px;
      }
    }
  }
  &.choice {
    padding: 88px 91px 49px 81px;
    text-align: center;
    p {
      color: #5b5b5bff;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;
    }
    .buttons {
      text-align: center;
      margin-top: 44px;
      .cancel {
        margin-left: 35px;
      }
      .save {
        //padding: 9px 52px 6px;
        margin-left: 75px;
      }
    }
  }
  &.textPopup {
    padding: 90px 67px 49px 59px;
    text-align: center;
    p {
      color: #5b5b5bff;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;
      margin-bottom: 0;
    }
    .buttons {
      text-align: center;
      margin-top: 0;
      .save {
        margin: 40px 0 0;
        //padding: 9px 54px 7px 55px;
      }
    }
  }
  &.add-points-modal {
    .lines {
      padding: 8px 0 10px;
      .line {
        margin-top: 0;
        margin-bottom: 33px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .remove {
      margin-top: 25px;
    }
    .users button {
      position: relative;
      top: -3px;
    }
    .buttons button {
      margin-top: 0;
      margin-bottom: 0;
    }
    .modal-close-btn {
      top: 33px;
    }
    .names-wrapp {
      display: flex;
      flex-wrap: wrap;
      padding: 13px 0 0;
      min-height: 79px;
      margin-left: 0;
      .names {
        margin: 23px 23px 0 0;
      }
    }
    .title {
      padding-right: 30px;
    }
  }
}

.fancybox-close-small {
  width: 23px;
  height: 23px;
  right: 32px;
  top: 32px;
  outline: none !important;
  &:after {
    content: '';
    background: url('^assets/img/close_btn.png') center center no-repeat !important;
    width: 23px;
    height: 23px;
    top: 0;
    right: 0;
  }
}
.gallery-modal-wrap {
  width: 870px;
  max-width: 870px;
  height: 440px;
  .modal-content {
    // height: 100%;
  }
}
.gallery-modal {
  padding: 35px 7px 20px 30px;
  position: relative;
  font-size: 0;
  height: 100%;
  &__next {
    transform: rotate(-90deg);
    font-size: 20px;
  }
  &__prev {
    transform: rotate(90deg);
    font-size: 20px;
  }
  &_selected {
    display: inline-block;
    vertical-align: top;
    width: 69%;
    font-size: 14px;
  }
  &-wrapper {
    display: flex;
  }
  &_results-list {
    // display: inline-block;
    // vertical-align: top;
    // width: 31%;
    // font-size: 14px;
    // padding: 55px 61px 0 25px;
    // height: 100%;
    overflow-x: auto;
    display: flex;
    width: 100%;
  }
  &_result {
    margin-right: 10px;
    img {
      height: 120px;
    }
    video {
      height: 120px;
    }
    &:last-child {
      .gallery-modal_result-footer {
        padding-bottom: 0;
      }
    }
  }
  &_content {
    height: 322px;
    &__audio {
      background: var(--light-color-backgorund);
      position: relative;
      text-align: center;
    }
    &__photo {
      display: flex;
      align-items: center;
      img {
        max-height: 100%;
      }
    }
  }
  &_footer {
    padding-top: 8px;
    position: relative;
  }
  &_footer-line {
    padding-top: 3px;
  }
  &__controls {
    position: absolute;
    right: 0;
    top: 10px;
    padding-right: 5px;
  }
  &__control {
    font-weight: 500;
    cursor: pointer;
    padding: 0 5px;
  }
  &_audio-content {
    height: 95px;
    background: var(--light-color-backgorund);
    border: 1px solid #c2cdbe;
  }
  .gallery-modal_audio-icon {
    font-size: 40px;
    color: var(--light-color);
    height: 100%;
    width: 100%;
    line-height: 320px;
    &__small {
      line-height: 95px;
      text-align: center;
      display: block;
    }
  }
  &_result-footer {
    padding: 8px 0 23px;
    min-height: 50px;
  }
  &__single-media {
    .gallery-modal_selected {
      width: 100%;
      padding-right: 23px;
    }
    .gallery-modal_results-list {
      display: none;
    }
  }
}
