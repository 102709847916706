////////////////  page  /////////////////
.page {
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 30px;
  &__title {
    text-transform: uppercase;
    font-weight: 300;
    color: #5b5b5b;
    margin-bottom: 30px;
    font-size: 26px;
    &-small {
      font-size: 20px;
      font-weight: 500;
    }
  }
  &__top {
    &-trigger {
      text-transform: uppercase;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  &__right-panel {
    display: block;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    right: -45%;
    width: 43%;
    position: fixed;
    top: 0;
    height: 100%;
    background: #fff;
    z-index: 1;
    overflow-y: auto;
    transition: 0.3s all;
    &--active {
      right: 0;
    }
    &-close {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 23px;
      color: #b1b1b1;
      transform: rotate(90deg);
      cursor: pointer;
    }
    &--analytics {
      .mat-expansion-indicator {
        display: none;
      }
    }
  }
}
///////////// user-page //////////////
.user-page {
  &__header {
    margin-bottom: 20px;
    .col {
      display: flex;
      align-items: flex-end;
    }
  }
  &__title {
    margin-bottom: 0;
  }
  &__username {
    text-transform: none;
    font-size: 20px;
    font-weight: 400;
  }
  &__col {
    &-header {
      min-height: 40px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
    }
  }
  &__right-col {
    min-width: 300px;
    padding-left: 30px;
  }
  &__top-trigger {
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
  }
}
.archiv-controls {
  display: flex;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  &__status {
    color: #ff0301;
    margin-right: 10px;
    margin-left: 7px;
    text-transform: uppercase;
  }
}
///////////// titles ///////////////
.h1 {
  font-size: 26px;
  font-weight: 100;
  text-transform: uppercase;
}
.h2 {
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
}
.h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
///////// doc //////////////////
.doc {
  padding-left: 10px;
  padding-bottom: 15px;
  margin-bottom: 24px;
  &__title {
    text-transform: uppercase;
    color: #424242;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__links {
    margin-right: 16px;
    a {
      margin-right: 18px;
    }
  }
  &--bb {
    border-bottom: 1px solid #bfbfbf;
  }
  &__expansion-panel {
    box-shadow: none !important;
    .mat-expansion {
      &-panel-header {
        padding-left: 0;
      }
      &-indicator {
        position: relative;
        top: -8px;
      }
      &-panel-body {
        padding: 0;
        padding-bottom: 20px;
      }
    }
  }
}

.task-sidebar {
  max-width: 320px;
  @media (max-width: 1599px) {
    max-width: 270px;
  }
  &--bl {
    border-left: 1px solid #bfbfbf;
  }
  &__img {
    border: 2px solid #b5b5b5;
  }
}
.task-content {
  padding-right: 25px;
}
.task-date-wrapper {
  padding-top: 35px;
  padding-bottom: 22px;
  input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.45) !important;
  }
  .col-3 {
    max-width: 45%;
    flex: 0 0 45%;
  }
  .col-2 {
    max-width: 30%;
    flex: 0 0 30%;
  }
}
.panel-right {
  position: fixed;
  z-index: 900;
  right: -350px;
  top: 0;
  height: 100%;
  width: 350px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background: #fff;
  transition: 0.3s all;
  overflow-y: auto;
  max-width: 100%;
  &__title {
    text-transform: uppercase;
    font-size: 24px;
  }
  &__inner {
    padding: 45px 15px 30px 20px;
  }
  .custom-radio {
    display: flex;
    align-items: center;
    label {
      font-size: 14px;
    }
    .circle {
      top: 2px;
    }
  }
  &--show {
    right: 0;
  }
  &__close {
    top: 15px;
    right: 20px;
    display: inline-block;
    position: absolute;
    cursor: pointer;
  }
}
.cpointer {
  cursor: pointer;
}
.word-break {
  word-break: break-word;
}
///////////////// ag-tables ////////////////////
.ag-row-selected {
  background-color: #f5f9f0 !important;
  border-color: #aad98f !important;
}
.ag-cell-focus {
  border: 1px solid var(--primary-color) !important;
}
.ag-cell-data-changed-animation {
  background-color: var(--primary-color) !important;
}
