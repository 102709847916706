/* @todo remove duplicates */

////////////////  page  /////////////////
.page {
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 30px;

  &__title {
    text-transform: uppercase;
    font-weight: 300;
    color: #5b5b5b;
    margin-bottom: 30px;
    font-size: 30px;
  }
  &__top {
    &-trigger {
      text-transform: uppercase;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}
////////////////  backlink  /////////////////
.backlink {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &__icon {
    font-size: 16px;
    margin-right: 5px;
  }

  &__link {
    color: #515151;
    font-size: 12px;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;

      .backlink {
        &__text {
          text-decoration: none;
        }
      }
    }
  }

  &__text {
    text-decoration: underline;
    color: #515151;
  }
}
////////////////  search  /////////////////
.search {
  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__icon {
    margin-right: 10px;
    color: #707070;
  }
}
/////////////////// filters ///////////////////
.filters {
  display: flex;
  font-size: 15px;
  align-items: center;

  &__title {
    margin-right: 14px;
    color: var(--light-color);
  }

  &__wrapper {
    display: flex;
  }

  &__item {
    background: #f2f5f1;
    display: inline-block;
    padding: 3px 14px;
    cursor: pointer;
    color: var(--light-color);

    &--active {
      background: var(--primary-color);
      color: #fff;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
//////////// data-table ////////////////
.data-table {
  width: 100%;
  thead {
    background: $ag-head-bg;
  }
  th {
    background: $ag-head-bg;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
    min-height: 34px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  td {
    padding-left: 15px;
    padding-right: 15px;
    height: 48px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 13px;
  }
  &--center {
    th,
    td {
      text-align: center;
    }
  }
  &--scroll {
    tbody {
      overflow-y: auto;
      height: 240px;
    }
  }
  &--scroll,
  &--perfect-scroll {
    thead,
    tbody,
    th,
    td {
      display: block;
    }

    tr {
      display: flex;
      min-width: 100%;
    }

    td,
    th {
      min-height: 34px;
      height: auto;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        width: 5%;
        justify-content: center;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
        justify-content: center;
      }
      &:nth-child(6) {
        width: 15%;
        justify-content: center;
      }
      &:nth-child(7) {
        width: 5%;
        justify-content: center;
      }
      &:nth-child(8) {
        width: 5%;
        justify-content: center;
      }
      &:nth-child(9) {
        width: 5%;
        justify-content: center;
      }
    }
    ul {
      margin-bottom: 0;
    }
  }
}

///////////// titles ////////////
// .h1 {
// 	font-weight: 300;
// 	font-size: 30px;
// 	text-transform: uppercase;
// 	margin-bottom: 20px;
// }
// .h2 {
// 	font-size: 18px;
// 	color: #424242;
// 	text-transform: uppercase;
// 	margin-bottom: 20px;
// }

//////////// trigger /////////////
.trigger {
  display: inline-flex;
  align-items: center;
  padding: 2px 20px;
  cursor: pointer;

  &__icon {
    font-size: 23px;
    color: var(--primary-color);
    margin-right: 9px;

    &_red {
      color: #ff0301ff;
    }
  }

  &__text {
    font-size: 16px;
    color: #5b5b5b;
  }

  &--disabled {
    .trigger {
      &__icon {
        color: #cdcdcd;
      }

      &__text {
        color: #cbcbcb;
      }
    }
  }

  &--min {
    padding-left: 0px;
    padding-right: 0px;
  }

  &--br {
    border-right: 1px solid #ddd;
  }
}

////////////// steps ///////////////
.steps {
  &__triggers {
    margin-bottom: 10px;
  }
}
////////// triggers //////////////
.triggers {
  margin-bottom: 10px;
  .trigger:first-child {
    padding-left: 5px;
  }
}

////////////////////// form  ///////////////////
.form {
  &__row {
    margin-top: 10px;
  }

  &__buttons {
    padding-top: 18px;
  }
}
/////////////////// techcard-form ///////////////////
.techcard-form {
  max-width: 790px;
  margin-bottom: 50px;
}
.techcard-statusbar {
  margin-bottom: 10px;

  &__status {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 500;
    margin-right: 25px;
    &--archive {
      color: $gray;
    }
  }
}

.techcard-info {
  &__inner {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__block {
    @apply px-4;
    border-left: 1px solid #d2d2d2;
    &:first-child {
      border-left: none;
    }
  }
}
/////////////////// colors ///////////////////
.green {
  color: $green;
}
.bg-grey {
  background: $color-light-grey-bg;
}
/////////////////// field-data ///////////////////
.field-data {
  &__label {
    font-size: 12px;
    color: #969696;
  }

  &__value {
    color: #424242;
    b {
      font-weight: 500;
    }
  }

  &--inline {
    display: flex;
    margin-right: 30px;

    .field-data {
      &__label {
        margin-right: 10px;
      }

      &__value {
        position: relative;
        bottom: 5px;
      }
    }
  }
}
/////////////////// form ///////////////////
.form {
  &__buttons {
    padding-top: 18px;
  }
}
/////////////////// decorated ///////////////////
.decorated {
  &__info {
    margin-bottom: 30px;
  }

  &__statusbar {
    margin-bottom: 25px;
  }
}
/////////// date-range //////////////
.date-range {
  &__label {
    color: var(--light-color);
    margin-right: 10px;
    font-size: 15px;
  }

  &__input {
    border: none;
    border-bottom: 1px solid $color-border-black;
  }
}
//////////////// author ///////////////
.author {
  &__label {
    color: #969696;
    font-size: 12px;
    margin-right: 10px;
  }
  &__name {
    color: #424242;
    font-size: 16px;
    font-weight: 500;
  }
}
//////////////// step-panel ///////////////
.step-panel {
  padding: 25px;
  max-height: calc(100vh - 90px);
  position: relative;
  &__title {
    font-size: 24px;
    text-transform: uppercase;
    color: #5b5b5b;
    font-weight: 500;
  }
  &__inp {
    align-items: center;
    margin-right: 15px;
  }
  &__cur {
    margin-left: 10px;
  }
  &__block {
    margin-bottom: 20px;
  }
  &__btns {
    text-align: center;
    padding-top: 10px;
    clear: both;
  }
  .mat-action-row {
    border: 0;
  }
  &__form {
    padding-bottom: 30px;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }
  &__icon {
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 24px;
    color: $gray;
  }
}
//////////////// step-expand ///////////////
.step-expand {
  position: relative;
  overflow: hidden;
  box-shadow: none !important;
  .mat-expansion-panel-header {
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
    font-size: 18px;
    color: #424242;
  }
  .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
  }
  textarea.mat-input-element {
    min-height: 70px;
  }
  .mat-content {
    flex: 0 1 auto;
  }
  .mat-expansion-indicator {
    height: 34px;
  }
  &__delete-item {
    color: #f03314;
    font-size: 16px;
    cursor: pointer;
  }
  &__save-item {
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
    &:before {
      content: '';
      width: 17px;
      height: 12px;
      border-left: 1px solid var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
      display: inline-block;
      transform: rotate(-45deg);
      position: relative;
      top: -4px;
    }
  }
  &__item {
    display: flex;
    &-col {
      flex: 1;
      padding-right: 10px;
    }
  }
  &__icon-col {
    display: flex;
    align-items: center;
  }
}
//////////////// custom-check ///////////////
.custom-check {
  display: inline-block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__mark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #acacac;
  }
}

/* On mouse-over, add a grey background color */
.custom-check:hover input ~ .custom-check__mark {
  border: 1px solid #777;
}

/* When the checkbox is checked, add a blue background */
.custom-check input:checked ~ .custom-check__mark {
  border: 1px solid #acacac;
  border-radius: 1px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-check__mark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked ~ .custom-check__mark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-check .custom-check__mark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
//////////////// checks ///////////////
.checks {
  &__item {
    margin-left: 20px;
  }
}
.step__add-item-btn {
  position: absolute;
  text-transform: uppercase;
  color: #464646;
  left: 0;
  bottom: 30px;
  border-bottom: 1px dashed #464646;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
}
//////////////// form-data ///////////////
.form-data {
  &__label {
    font-size: 12px;
    color: #b4b4b4;
  }
}
//////////////// check-result ///////////////
.check-result {
  padding-left: 20px;
  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 12px;
    border: solid var(--primary-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 7px;
  }
}
//////////////// video-tutorial ///////////////
.video-tutorial {
  display: flex;
  &__link {
    text-transform: uppercase;
    color: #464646;
    border-bottom: 1px dashed #464646;
    &:hover {
      text-decoration: none;
      color: #464646;
      border-bottom: none;
    }
  }
  &__links {
    &-item {
      margin-bottom: 20px;
    }
  }
  &__link {
    cursor: pointer;
  }
  &__video {
    min-width: 65%;
    max-width: 65%;
    float: left;
    margin-right: 20px;
    img {
      max-width: 100%;
      min-width: 100%;
    }
  }
}
//////////////// link ///////////////
.link {
  color: #424242;
  &:hover {
    color: #424242;
  }
  &--dashed {
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 100%;
      bottom: 0;
      border-bottom: 1px dashed #424242;
    }

    &:hover {
      text-decoration: none;
      &:before {
        display: none;
      }
    }
  }
  &--uppercase {
    text-transform: uppercase;
  }
}

.icon {
  &__replace-arrow {
    width: 18px;
    height: 18px;
    display: inline-block;
    background: url('^assets/img/replace-arrow.png') 0 0 no-repeat;
    cursor: pointer;
  }
}

.step-btns-fixed {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding-bottom: 15px;
  width: 26%;
}
.edit-password,
.link-upper-dashed {
  text-transform: uppercase;
  color: #5b5b5b;
  border-bottom: 1px dashed #5b5b5b;
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    border-color: #fff;
    color: #5b5b5b;
    text-decoration: none;
  }
}
.data-label {
  font-size: 13px;
  color: #969696;
  padding-right: 5px;
}
