//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @override angular material
.mat-form-field {
  width: 100%;
}
// @override ng-select
.form-ng-select {
  position: relative;
  margin-top: 16px;
  label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-1.28125em) scale(0.75);
    color: #424242;
    transform-origin: 0 0;
  }
  .ng-select .ng-control {
    max-width: none;
  }
  .ng-select .ng-dropdown-panel-items {
    max-width: none !important;
  }
}
// @override default itorum roles
.form-roles {
  display: block !important;
  margin-top: 0 !important;
  &.roles {
    h3 {
      text-align: left;
      margin-bottom: 10px;
      text-transform: none;
      font-weight: 400;
      color: #424242;
    }
    .line {
      margin-top: 15px;
    }
  }
}
// add remove icon to angular material select
.mat-select-trigger {
  .cross-btn {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 4px;
    right: 22px;
  }
}
// @override angular material
BODY {
  // need for selector specificity, !important - is evil )
  .mat-form-field {
    display: block;
    //margin: 15px 0;
  }
  .mat-form-field-invalid {
    color: #ff0301ff;
  }
  .mat-form-field-label {
    color: #424242;
    // color: #969696;
  }
  .mat-focused .mat-form-field-label {
    color: #424242;
  }
  .mat-form-field-ripple {
    background-color: var(--primary-color) !important;
  }
  .mat-form-field .mat-form-field-label .mat-form-field-required-marker {
    color: inherit;
  }
  .mat-select-trigger {
    .mat-select-arrow-wrapper:before {
      width: 15px;
      height: 15px;
      top: 5px;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAgNDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwIDQwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6IzdhN2E3YTt9PC9zdHlsZT48cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjMzLjcsOS42IDIwLjIsMjMuMSA2LjcsOS42IDMuMywxMyAxOS43LDI5LjQgMjAuMiwyOS44IDM3LDEzICIvPjwvc3ZnPg==)
        center center no-repeat;
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #71bf44;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #71bf44;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #71bf44;
}
//.mat-option-text {
//  @apply text-gray-darker;
//}

.mat-primary {
  .mat-option {
    .mat-option-text {
      @apply text-gray-darker;
    }
    &.mat-active {
      background: #ddd;
    }
    &.mat-selected {
      .mat-option-text {
        @apply text-black;
      }
    }
  }
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    @apply bg-primary;
    //background: var(--primary-color);
  }
}

.mat-calendar-body-selected {
  //background-color: #4db73a;
  //color: #fff;
  @apply bg-primary text-white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  @apply bg-primary opacity-30;
}

//.mat-calendar-body-in-range {
//  @apply bg-primary bg-opacity-10;
//}

.mat-form-field-appearance-fill .mat-form-field-flex {
  @apply bg-white;
  padding: 0 !important;
}

.mat-datepicker-toggle-active {
  @apply text-primary;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element {
  @apply bg-primary;
  //background-color: green !important;
}

.mat-checkbox-indeterminate,
.mat-checkbox-checked {
  &.mat-primary .mat-checkbox-background {
    //background-color: green !important;
    @apply bg-primary;
  }

  //.mat-tab-group.mat-primary .mat-ink-bar {
  //  @apply bg-primary;
  //}
  //
  //.mat-ink-bar {
  //  @apply bg-primary;
  //}
  //
  //.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  //  @apply bg-primary;
  //}

  //&.mat-accent .mat-checkbox-background {
  //  background-color: mat-color($accent);
  //}
  //
  //&.mat-warn .mat-checkbox-background {
  //  background-color: mat-color($warn);
  //}
}

//.mat-expansion-panel-body {
//  //@apply shadow-none;
//  padding-right: 5px !important;
//  padding-left: 5px !important;
//}
