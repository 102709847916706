//@keyframes side-panel-hide {
//  from {
//    right: 0;
//  }
//  to {
//    width: 0;
//  }
//}

@keyframes side-panel-show {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

.side-modal {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    // кнопки края
    background: #eee;
  }
  &::-webkit-scrollbar-track-piece {
    // Фон
    background: #ccc;
  }
  &::-webkit-scrollbar-thumb {
    // Динамический скролл
    background: #888;
  }
  max-width: 350px; // hack
  overflow-y: scroll;
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 900;
  top: 0;
  right: -100%;
  //animation-name: side-panel-hide;
  //animation-duration: 2s;
  overflow-x: hidden;
  background: $color-white;
  border-left: 1px solid $color-shadow;
  box-shadow: -2px 1px 7px 0px $color-shadow;
  transition: right 1s 0s ease;
  &._show {
    right: 0;
  }
  @media screen and (min-width: 1900px) {
    display: none;
    position: fixed;
    //z-index: 0;
    right: 0;
    .side-modal__content {
      min-width: 310px;
      max-width: 310px;
    }
    //.side-modal__close {
    //    display: none;
    //}
    &._create {
      display: block;
      animation-name: side-panel-show;
      animation-duration: 400ms;
      animation-timing-function: ease-in;
    }
    &__active-btn {
      display: none;
    }
  }
  &__content {
    display: block;
    box-sizing: border-box;
    margin: 30px 30px 30px 26px;
    padding: 0;
  }
  &__close {
    display: inline-block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 9px;
    right: 25px;
    z-index: 1;
    cursor: pointer;
    font-size: 20px;
    color: var(--secondary-color-x3);
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: url('~assets/img/up.svg') no-repeat center center;
    transform: rotate(90deg);
    &:hover {
      color: $color-lightBlack-active;
      transform: rotate(90deg) scaleX(1.2);
    }
  }
}

.modal-form {
  &__hd {
    display: block;
    padding: 0;
    margin: 33px 0 0.5em 0;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: normal;
    color: #5b5b5bff;
    &._ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__field {
    display: block;
    margin: 15px 0 0;
  }
  &-actions {
    padding: 0;
    margin: 10px 0 20px 0;
    text-transform: uppercase;
    &__alert,
    &__restore,
    &__remove {
      padding: 0;
    }
    &__restore,
    &__remove {
      cursor: pointer;
      &:hover {
        color: #212529;
        .icon-basket,
        .icon-basket-restore {
          transform: scale(1.4);
        }
      }
    }
    .icon-basket,
    &__alert {
      color: #ff0301ff;
      padding: 0;
    }

    &__alert {
      margin: -1px 8% 0 0;
    }
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    // margin: 0;
    // padding: 0;
    // button, &__cancel, &__save  {
    //     display: inline-block;
    //     padding: 0.9em 1.9em;
    //     margin: 0;
    //     cursor: pointer;
    //     border-radius: 5px;
    //     &:hover {
    //         background: var(--primary-color-darken-5);
    //     }
    // }
    // &__cancel {
    //     margin-right: 0.5em;
    //     background: transparent;
    //     color: #666666FF;
    //     &:hover {
    //         background: $color-material-btn-hover;
    //         color: #212529;
    //     }
    // }
    &__button {
      margin-right: 10px;
      border: 1px solid #fff;
      padding: 13px 25px 12px;
      color: #666666ff;
      &:hover {
        background: $color-light-grey-bg;
        border: 1px solid $color-light-grey-bg;
        color: $gray;
      }
    }
  }
}
