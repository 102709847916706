.ag-theme-balham .ag-header-cell {
  font-weight: normal;
  font-size: 18px;
}

.ag-theme-balham .ag-icon {
  font-size: 24px;
}

.ag-theme-home .ag-header-cell {
  font-weight: normal;
  font-size: 18px;
}

.ag-theme-balham .ag-icon {
  font-size: 24px;
}

.ag-theme-home {
  border-style: none !important;
}

// .ag-theme-balham .ag-root-wrapper {
//   border: none !important;
// }
