.default-cell-setup {
  @apply flex items-center font-bold text-sm;
}

.template {
  color: #da950b;
}

.formalized {
  color: #64b621;
}

.new {
  color: #8f99a3;
}

.assigned {
  color: #8f99a3;
}

.running {
  color: #c1a30b;
}

.completed {
  color: #64b621;
}

.approved {
  color: #64b621;
}

.expired {
  color: #d74242;
}
//"new" | "started" | "canceled" | "finished";

.started {
  color: #64b621;
}

.canceled {
  color: #d74242;
}

.finished {
  color: #8f99a3;
}

.course-new {
  color: #8f99a3;
}

.course-running {
  color: #c1a30b;
}

.course-testing {
  color: #c1a30b;
}

.course-review {
  color: #c1a30b;
}

.course-passed {
  color: #64b621;
}

.course-not_passed {
  color: #d74242;
}

.course-overdue {
  color: #d74242;
}
