// стандартный линк
.link-upper-dashed {
  @apply uppercase text-gray-dark border-b border-dashed border-gray-dark no-underline text-base cursor-pointer hover:border-white hover:text-gray-dark hover:no-underline;
}

.link-regular {
  @apply underline text-gray-dark capitalize-first hover:opacity-80;
}
.link-common {
  @apply text-gray-dark capitalize-first hover:opacity-80;
}

.link-report {
  @apply inline-block leading-tight text-left text-sm border-b border-dashed text-errors border-errors uppercase cursor-pointer font-normal;
}

.addPoints {
  @apply block relative rounded-md bg-primary no-underline text-white text-base uppercase leading-none px-6 py-2.5 hover:no-underline hover:text-white;
  //padding: 11px 23px 9px;
}

form {
  .field {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &.competence {
      input {
        width: 568px;
      }
    }
    label {
      margin: 1px 33px 0 0;
      color: $color-black;
      line-height: 1.3;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      min-width: 168px;
      text-align: right;
    }
    input {
      width: 370px;
      height: 30px;
      line-height: 1.2;
      font-size: 18px;
      color: $color-black;
      border: none;
      border-bottom: 1px solid $color-lightBlack-active;
      outline: none;
    }
    .error {
      display: none;
      position: absolute;
      font-size: 13px;
      color: #e13339;
    }
    &.no-valid {
      input {
        border-color: #e13339 !important;
        color: #e13339 !important;
      }
      .error {
        display: block;
      }
    }
    & > span {
      position: relative;
    }
  }

  .roles {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    h3 {
      margin: 2px 33px 0 0;
      color: $color-black;
      line-height: 1;
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      min-width: 168px;
      text-align: right;
    }
    input[type='checkbox'] {
      display: none;
      &:checked ~ label {
        color: #333;
        &:before {
          border-color: var(--primary-color);
        }
        &:after {
          opacity: 1;
        }
      }
      &:checked ~ span.role-list__label {
        &:after {
          display: block;
        }
      }
    }
    .line {
      margin-top: 25px;
      &:first-child {
        margin-top: 0;
      }
    }
    label {
      position: relative;
      padding-left: 35px;
      margin-bottom: 0;
      color: $color-grayBlack;
      font-size: 16px;
      line-height: 1.2;
      cursor: pointer;
      transition: all 0.3s ease;
      &:before {
        position: absolute;
        content: '';
        width: 18px;
        height: 18px;
        left: 0;
        top: 50%;
        margin-top: -9px;
        border-radius: 2px;
        border: 1px solid #acacac;
        transition: all 0.3s ease;
      }
      &:after {
        position: absolute;
        content: '';
        width: 17px;
        height: 13px;
        background: url('^assets/img/check_user.png') center center no-repeat;
        left: 0;
        top: 50%;
        margin-top: -6px;
        opacity: 0;
        border-radius: 2px;
        transition: all 0.3s ease;
      }
    }
    label.role-list__container {
      &:before {
        border: none;
      }
    }
    span.role-list__label {
      position: absolute;
      left: 0;
      top: 1px;
      height: 18px;
      width: 18px;
      border: 1px solid #acacac;
      border-radius: 1px;
      &:after {
        display: none;
        position: absolute;
        left: 5px;
        top: 0;
        content: '';
        width: 7px;
        height: 13px;
        border: solid var(--primary-color);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .chiefsBlock {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h3 {
      margin: 0 33px 0 0;
      color: $color-black;
      line-height: 1;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      width: 150px;
      text-align: right;
      line-height: 1.2rem;
    }
    .ng-select {
      .ng-control {
        min-width: 200px;
        max-width: 370px;
      }
    }
    .select2-container {
      width: auto !important;
    }
    .select2-selection {
      position: relative;
      min-width: 330px;
      min-height: 30px;
      padding-right: 20px;
      line-height: 1.2;
      font-size: 18px;
      color: $color-black;
      border: none;
      border-bottom: 1px solid $color-lightBlack-active;
      outline: none;
      border-radius: 0;
      &[aria-expanded='true'] {
        &:before {
          transform: rotate(-180deg);
        }
      }
      &:before {
        position: absolute;
        content: '';
        width: 11px;
        height: 6px;
        background: url('^assets/img/arrow_down.png') center center no-repeat;
        right: 5px;
        top: 50%;
        margin-top: -3px;
        transition: all 0.3s ease;
      }
      .select2-selection__choice {
        color: $color-black;
        font-size: 18px;
        background: transparent;
        border: none;
        line-height: 1.2;
        margin-bottom: 5px;
      }
    }
  }

  .names {
    display: inline-block;
    vertical-align: middle;
    color: $color-black;
    font-size: 16px;
    line-height: 1.2;
    margin: 37px 31px 0 0;
    a {
      margin-top: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      text-decoration: none;
    }
    &-wrapp {
      margin-left: 202px;
    }
  }

  .password-rule {
    margin-left: 29px;
    color: #888888;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    top: -10px;
  }
  .password-show {
    position: absolute;
    width: 20px;
    height: 15px;
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAABxUlEQVQ4T61Uv4vUUBCeb5LFVSsVQbFxrSxUFASx0tJOuMrSSk6uORey2YQFI+y+vCSw2IjHcX+A1amdrZUIB3fIFVbeNYIgaKWubPJGsiTyXPb2DrkUCfPre1/mm3mgPZ4sy1p5nt9i5ksicrpMA/DVGLPtuu5bz/N2ZpVi2jkcDk+Ox+N7InJjr8Mq8PeNRuNFu93+Zuf9A5hl2ZWiKBaJ6LiVtAvgU2mLyAUiOm/FfjiOs+J53ofa9xdQKXWTmR8QEVfB78aYlTAMP9oMlFIXmbk89ETlN8aY1TAM302Yl68kSa6LyJIF9ouZo06n82XWb6dpesYYExHR0RoUwDPf9zcwGAzOOo7zhIiOWMXr3W73dW0rpU4xcwzgje/766Vfa32XiBasmt9FUTxGHMePAFy1meR5HvZ6vc9V35Cm6X0RuV3ZT4Mg2Oz3++dc11V2nYhsQWu9RkQNOzAajR5GUfRTa30ZwEIlxiRFRF4FQfAyiqJjzWbz+VRLxnMZxnF8DcDyFIv5DPfrYZIkJcM71ezt38ODqCwik2kAIOV3rsqWkoc3hzXorE0RkR1mnuytMaYFoHWgTamTDnWXbTX/97b5A4mND6+x7yaNAAAAAElFTkSuQmCC')
      center no-repeat;
    top: 0px;
    right: 5px;
    cursor: pointer;
  }

  .reset {
    color: $color-black;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid$color-black;
    margin-left: 29px;
  }

  .apply {
    margin-top: -8px;
    padding: 10px 33px;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #ffffff;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 29px;
    line-height: 1;
    border: 1px solid var(--primary-color);
    &.disabled {
      background-color: transparent;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      text-decoration: none;
    }
  }

  button {
    // outline: none;
    // outline: none;
    // border: none;
    // cursor: pointer;
    // padding: 19px 57px 17px;
    // background-color: var(--primary-color);
    // border-radius: 5px;
    // color: #ffffff;
    // font-size: 18px;
    // line-height: 1;
    // text-transform: uppercase;
    // margin: 42px 0 70px 201px;
  }
}
