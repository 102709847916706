ul,
ol {
  padding-left: 20px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}
