.buttons {
  @apply flex flex-wrap items-baseline;

  .text {
    @apply text-buttonStack-text leading-tight text-base font-light mr-2;
  }

  .buttons-container {
    @apply flex;

    .switch-button {
      @apply no-underline cursor-pointer text-buttonStack-text border-r bg-buttonStack-background leading-7 font-normal text-base border-t border-b border-primary-dark;
      //background-color: var(--light-color-backgorund);
      padding: 4px 10px 4px 13px;
      //height: 28px;
      //line-height: 28px;
      transition: background-color 0.3s, color 0.3s;
      //font-weight: 300;
      //font-size: 15px;
      border-color: #1e1e1e1a;

      &__left {
        @apply border-l border-r;
        border-radius: 5px 0 0 5px;
      }
      &__right {
        @apply border-r;
        border-radius: 0 5px 5px 0;
      }
    }

    .switch-button.active {
      @apply cursor-default bg-primary text-white font-normal;
    }
  }
}

// single button
.button {
  padding: 5px 15px;
  display: inline-block;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
  cursor: pointer;
  background: #fff;
  min-width: 100px;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.3s all;
  text-decoration: none;

  &:hover {
    color: #fff;
    background: var(--primary-color-darken-10);
    border: 1px solid var(--primary-color-darken-10);
    text-decoration: none;
  }
}

.disabled {
  opacity: 0.4 !important;
  cursor: default !important;
}
