.form-group {
  margin-bottom: 20px;
}

.label-block {
  display: block;
}

.label-text {
  display: block;
  margin-bottom: 5px;
}

.form-group {
  position: relative;
}

.errorText {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  color: red;
}

.form-control {
  &:focus {
    box-shadow: 0 0 10px 0 #d4e8ff;
  }
  &.error {
    box-shadow: 0 0 6px 0 #ff6666;
  }
  &[aria-invalid='false'] {
    box-shadow: 0 0 2px 0 green;
  }
}

// .radio, .checkbox {
// 	margin-right: 30px;
// 	margin-bottom: 15px;
// 	vertical-align: top;
// 	overflow: hidden;
// 	&--inline {
// 		display: inline-block;
// 	}
// 	.label-text {
// 		display: inline;
// 		vertical-align: middle;
// 	}
// 	input {
// 		opacity:0;
// 		visibility: hidden;
// 		position: absolute;
// 		&+span {
// 			position: relative;
// 			display: inline-block;
// 			vertical-align: middle;
// 			margin-right: 5px;
// 			width: 30px;
// 			height: 30px;
// 			border:1px solid #ccc;
// 			cursor: pointer;
// 		}
// 		&.error+span {
// 			border-color: red;
// 		}
// 		&:checked:not(disabled) {
// 			&+span {
// 				&:before {
// 					content: '';
// 					display: block;
// 					width: 20px;
// 					height: 20px;
// 					position: absolute;
// 					top: 50%;
// 					background: #cccccc;
// 					left: 50%;
// 					transform: translate(-50%, -50%);
// 				}
// 			}
// 		}
// 		&:disabled+span {
// 			opacity: 0.7;
// 			cursor: not-allowed;
// 		}
// 	}
// }

// .radio {
// 	input {
// 		&+span, &+span:before {
// 			border-radius: 50%;
// 		}
// 	}
// }

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  display: none;
}

///////////////////// true-checkboxes /////////////////////
/* Customize the label (the container) */
.checkbox {
  display: inline-block;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding-left: 34px;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox__text {
  cursor: pointer;
}
/* Create a custom checkbox */
.checkbox__mark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  display: inline-block;
  border: 1px solid #acacac;
  cursor: pointer;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox__mark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkbox__mark:after {
  display: block;
}

/* Style the checkbox__mark/indicator */
.checkbox__mark:after {
  display: none;
  position: absolute;
  left: 5px;
  top: 0;
  content: '';
  width: 7px;
  height: 13px;
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

:host ::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: green !important;
}

::ng-deep .wrap {
  /*change color of label*/
  background: green !important;
}
