.btn {
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 15px;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &-default {
    background: #dddddd;
    color: #ffffff;
    &:hover {
      background: #9f9f9f;
      color: #ffffff;
    }
  }

  &-icon {
    padding-right: 43px;
    .btn-icon__content {
      position: relative;
      padding-left: 43px;
    }

    &__img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: #fff;
      font-size: 24px;
      line-height: 18px;
    }
  }
}
/////////////////// button ///////////////////
.button {
  padding: 5px 15px;
  display: inline-block;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
  cursor: pointer;
  background: #fff;
  min-width: 100px;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.3s all;
  text-decoration: none;
  &:hover {
    color: #fff;
    background: var(--primary-color-darken-10);
    border: 1px solid var(--primary-color-darken-10);
    text-decoration: none;
  }
  &--green {
    color: #fff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    &:hover {
      background: var(--primary-color-darken-10);
      border: 1px solid var(--primary-color-darken-10);
    }
  }
  &--red {
    color: #fff;
    background: #df242b;
    border: 1px solid #df242b;
    &:hover {
      background: #a31a20;
      border: 1px solid #df242b;
    }
  }
  &--black {
    color: #fff;
    background: rgba(0, 0, 0, 0.61);
    border: 1px solid #000000;
    &:hover {
      background: rgba(0, 0, 0, 0.31);
      border: 1px solid rgba(0, 0, 0, 0.61);
    }
  }
  &--lg {
    padding: 13px 45px 12px;
    font-size: 16px;
    min-width: 180px;
  }
  &--call-stop {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0;
    padding-bottom: 0;
    i {
      font-size: 45px;
      position: relative;
      top: 5px;
    }
  }
  &--transparent {
    border: 1px solid #fff;
    padding: 13px 25px 12px;
    color: #666;
    &:hover {
      background: #f4f4f4;
      border: 1px solid #f4f4f4;
      color: #b8b8b8;
    }
  }
  &--lg-md {
    padding: 13px 25px 12px;
  }
}
